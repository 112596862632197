@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.css";
@import 'https://fonts.googleapis.com/css2?family=Bitter:wght@400;700&display=swap';
@import 'remixicon/fonts/remixicon.css';

.ant-btn-primary {
  background-color: #1890ff;
}

.structure {
  display: grid;
  width: 100%;
  height: 250px;
  grid-template-areas: "head head"
    "nav  main";
  grid-template-rows: 50px 1fr;
  grid-template-columns: 200px 1fr;
}

.structure>header {
  grid-area: head;
}

.structure>nav {
  grid-area: nav;
  min-height: calc(100vh - 50px);
}

.structure>main {
  grid-area: main;
}

.nav-menu {
  @apply p-2 text-lg;

  li {
    @apply cursor-pointer py-1;

    i {
      @apply align-bottom;
    }

    &:hover,
    &.selected {
      @apply text-secondary
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0.25;
  }
}

.blink {
  animation: blink 1s linear infinite;
}

.recording-dot {
  @apply w-3 h-3 rounded-full bg-red-600 absolute left-0 top-0 ml-3 mt-3;
  animation: blink 2s linear infinite;
}

.cam-rotation {
  $w: 350px;
  $h: 175px;

  width: $w;
  height: $h;
  position: relative;

  video {
    width: $w;
    height: $h;
    aspect-ratio: "16/9";
    transform-origin: top left;
  }

  &.cam-rotation-0 {}

  &.cam-rotation-90 {
    width: $h;
    height: $w;
    left: $h;

    video {
      position: absolute;
      transform: rotate(90deg);
    }
  }

  &.cam-rotation--90 {
    width: $h;
    height: $w;
    top: $w;

    video {
      position: absolute;
      transform: rotate(-90deg);
    }
  }

  &.cam-rotation-180 {
    video {
      transform: rotate(180deg);
      transform-origin: center center;
    }
  }
}